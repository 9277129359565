export const de = {
    translations: {
        buttons: {
            save: "Save"
        }
    }, 
    app: {
        library: {
            title: "Bib",
            deleteCourseTitle: "Kurs löschen",
            deleteCourseMessage: "Du bist dabei einen Kurs zu löschen. Bist du dir sicher?\n Gelöschte Inhalte können nicht wiederhergestellt werden."
        }

    }, 
    landing: {
        
    }, 
    marketplace: {
        
    }, 
    hub: {
        appointment: {
            disabled:{
                title: "Nicht verfügbar",
                description: "Leider können wir aktuell keine online Terminvereinbarungen mehr anbieten. Wir werden uns zeitnah mit Ihnen in Verbindung setzen. Wir bitten um Ihr Verständniss."
            },
            sidebar: {
            title: "Termin vereinbaren", 
            subheading: "Hardwaretausch 2025 Zeppelin",
            welcomeText: "Bitte füllen Sie das Formular aus, um einen Termin für Ihre Hardwaretausch zu vereinbaren.",
            contact: "Bei Fragen stehen wir Ihnen jederzeit unter",
            contact2: "zur Verfügung.",
            approvalTitle: "Bestätigung ausstehend",
            approvalText: "Vielen Dank für Ihre Zeit! Da Sie eine abweichende Ortsangabe gemacht haben können wir diesen Termin noch nicht final bestätigen. Wir setzen uns in Kürze mit Ihnen in Verbindung.",
            appointedTitle: "Termin vereinbart",
            appointedText: "Die Termine für Ihren Rechnertausch wurden erfolgreich registriert. Sie erhalten in kürze eine Bestätigung per e-Mail."
            },
            form: {
                information: "Informationen",
                user: "Benutzer",
                computerName: "Rechnername",
                manufacturer: "Hersteller",
                model: "Modell",
                loading: "Lädt",
                alertAppointmentSuccess: "Termin wurde erfolgreich vereinbart.",
                alertAppointmentInquired: "Termin wurde erfolgreich angefragt.",
                location: "Standort",
                choosePlant: "Werk auswählen",
                chooseBuilding: "Standort auswählen",
                floor: "Stockwerk",
                roomNumber: "Raumnummer",
                otherInformation: "Bitte Standort angeben:",
                appointments: "Termine",
                migration: "Umstellung",
                migrationText: "Bitte wählen Sie einen Termin für  den Hardwaretausch Ihres Rechners. Diese wird von einem Servicetechniker vor Ort vorgenommen und ca. 30 Minuten dauern.",
                migrationText2: "Während der Umstellung, ist es Ihnen",
                migrationText3: "nicht",
                migrationText4: "möglich am Gerät zu arbeiten!",
                backup: "Datensicherung",
                backupText: "Der Termin für die Datensicherung muss vor der Umstellung liegen. Diese wird telefonisch mit einem Techniker vorgenommen und dauert ca. 15 Minuten.",
                notes: "Hinweise",
                notesText1: "Die Umstellung kann nur mit einer erfolgreichen Datensicherung durchgeführt werden!",
                notesText2: "Sobald die Datensicherung gestartet wurde, können Sie in dieser Zeit",
                notesText3: "nicht am Gerät arbeiten.",
                notesText4: "Nach der Sicherung bitten wir Sie keine Daten mehr auf C: abzulegen oder zu ändern.",
                notesText5: "Die Dauer der Datensicherung hängt von der Menge der lokalen (Festplatte „C:“) Daten ab.",
                notesText6: "Es besteht auch die Möglichkeit, die Sicherung auch selbständig über ein Automatisches Backup Tool zu starten. Diesen Link erhalten Sie in einer E-Mail nach Bestätigung der Termine.",
                recovery: "Rücksicherung",
                recoveryText1: "Die Rücksicherung erfolgt nach der Umstellung um Ihre Daten wieder aufzuspielen.",
                recoveryText2: "Diese dauert ca. 15 – 30 Minuten und erfolgt telefonisch.",
                recoveryText3: "Die Einrichtung der Profileinstellungen erfolgt im gleichen Termin.",
                others: "Sonstiges",
                computerType: "Art des Rechners",
                multipleUsers: "Arbeiten mehrer Benutzer an dem Gerät?",
                otherUserNr: "Stammnummern der weiteren Nutzer hinzufügen:",
                software: "Softwarepakete (optional)",
                otherNotes: "Sonstige Anmerkungen (optional)",
                error:"Leider ist ein Problem aufgetreten. Bitte versuchen Sie es erneut oder laden Sie die Seite neu.",
                errorAlert: "Bitte Alle Pflichtfelder (mit roter Box markiert) ausfüllen!",
                submit: "Abschicken"
            }
        },
        form: {
            sidebar: {
            title: "Rechner Daten", 
            subheading: "ZF Friedrichshafen AG",
            welcomeText: "Bitte füllen Sie das Formular aus, um die fehlenden Daten zu Ihrem Rechner zu ergänzen.",
            contact: "Bei Fragen stehen wir Ihnen jederzeit unter",
            contact2: "zur Verfügung.",
            appointedTitle: "Daten aktualisiert",
            appointedText: "Vielen Dank für Ihre Zeit. Wir haben Ihre Daten erfolgreich aktualisiert."
            },
            form: {
                information: "Informationen",
                user: "Benutzer",
                computerName: "Rechnername",
                manufacturer: "Hersteller",
                category: "Kategorie",
                model: "Modell",
                loading: "Lädt",
                alertAppointmentSuccess: "Vielen Dank für Ihre Zeit. Wir haben Ihre Daten erfolgreich aktualisiert.",
                location: "Standort",
                choosePlant: "Werk auswählen",
                chooseBuilding: "Gebäude auswählen",
                floor: "Stockwerk",
                roomNumber: "Raumnummer",
                otherInformation: "Andere Informationen",                
                error:"Leider ist ein Problem aufgetreten. Bitte versuchen Sie es erneut oder laden Sie die Seite neu.",
                errorAlert: "Bitte Alle Pflichtfelder (mit roter Box markiert) ausfüllen!",
                submit: "Abschicken",
                replace: "Rechnertausch"

            }
        }
    }
}