import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { UpdateAppointmentDialog } from "../AppointmentsDialog";
import Drawer, {
  DrawerSection,
  DrawerAlertBox,
  DrawerItem,
  DrawerInfoItem,
  DrawerUserSection,
  DrawerForm,
  DrawerFormItem,
  DrawerCommentSection,
  DrawerActionItem,
  DrawerPage,
} from "../Drawer";
import ReduxObserver from "../ReduxObserver";
import {
  selectAppointmentRessource,
  selectAppointment,
  selectIsLoading,
  selectUsers,
} from "../../store/selectors";
import {
  updateAppointment,
  assignUser,
  removeAssignee,
  updateRessource,
  updateRessourceStatus,
  openReport,
  addComment,
} from "../../store/actions";

import { withFormik } from "formik";
import Button from "../elements/Button";
import Field from "../elements/Field";
import Select from "../elements/Select";

import Badge from "../elements/Badge";
import config from "../../config";

const EditSoftwareForm = (props) => {
  const [_prevSite, _setPrevSite] = useState("");
  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    setFieldValue,
    handleSubmit,
    submitForm,
  } = props;

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <DrawerForm>
          <DrawerFormItem title="Software hinzufügen">
            <Select
              isCreatable
              isMulti
              onChange={setFieldValue}
              name="software"
              value={values.software}
              options={[]}
            />
          </DrawerFormItem>
          <Button
            type="submit"
            block
            disabled={!isValid}
            isLoading={props.isLoading}
          >
            Speichern
          </Button>
        </DrawerForm>
      </form>
    </React.Fragment>
  );
};

const EditSoftwareSection = (props) => {
  const EnhancedForm = withFormik({
    handleSubmit: (values) => {
      props.onSubmit &&
        props.onSubmit({ software: values.software.map((e) => e.value) });
    },
    validate: (values) => {
      console.log(values);
      return {};
    },
    mapPropsToValues: ({ ressource }) => ({
      software:
        ressource.software &&
        ressource.software.map((e) => ({ value: e, label: e })),
    }),
  })(EditSoftwareForm);
  return <EnhancedForm {...props} />;
};

const AddCommentForm = (props) => {
  const [_prevSite, _setPrevSite] = useState("");
  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    setFieldValue,
    handleSubmit,
    submitForm,
  } = props;

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <DrawerForm>
          <DrawerFormItem title="Kommentar">
            <Field
              placeholder="Kommentar eingeben"
              component="textarea"
              name="description"
            />
          </DrawerFormItem>
          <Button
            type="submit"
            block
            disabled={!isValid}
            isLoading={props.isLoading}
          >
            Speichern
          </Button>
        </DrawerForm>
      </form>
    </React.Fragment>
  );
};

const AddCommentSection = (props) => {
  const EnhancedForm = withFormik({
    handleSubmit: (values) => {
      props.onSubmit && props.onSubmit(values);
    },
    validate: (values) => {
      return !values.description ? { description: "Darf nicht leer sein" } : {};
    },
    mapPropsToValues: ({ ressource }) => ({
      description: "",
    }),
  })(AddCommentForm);
  return <EnhancedForm {...props} />;
};

const SelectConfirmationSection = (props) => {
  return (
    <DrawerSection title="Protokoll">
      <DrawerActionItem
        onClick={() => {
          props.onRequestOpenConfirmation && props.onRequestOpenConfirmation();
        }}
        title="Protokoll ausfüllen"
        description="Protokol direkt selbst ausfüllen"
        btnTitle="Öffnen"
        block
      />
      <br />
      <DrawerActionItem
        onClick={() => {
          props.onRequestSendUserConfirmation &&
            props.onRequestSendUserConfirmation();
        }}
        confirmationMessage="Der Nutzer erhält sofort ein Formular, das er selbständig ausfüllen kann."
        confirmationTitle="Bist du sicher?"
        closeDialogOnConfirm={true}
        confirmation
        title="Formular versenden"
        description="User erhät Formular per E-Mail"
        btnTitle="Senden"
        variant="pastel-secondary"
        block
      />
    </DrawerSection>
  );
};

const CloseChangeForm = (props) => {
  const options = [
    {
      value: "done",
      label: "Erledigt",
    },
  ];
  const [_prevSite, _setPrevSite] = useState("");
  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    setFieldValue,
    handleSubmit,
    submitForm,
  } = props;

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <DrawerForm>
          <DrawerFormItem title="Umstellung erfolgt">
            <Select name="a" onChange={setFieldValue} options={options} />
          </DrawerFormItem>
          <DrawerFormItem title="Software übernommen">
            <Select name="b" onChange={setFieldValue} options={options} />
          </DrawerFormItem>
          <DrawerFormItem title="Rücksicherung">
            <Select name="c" onChange={setFieldValue} options={options} />
          </DrawerFormItem>
          <DrawerFormItem title="Rechner eingelagert">
            <Select name="d" onChange={setFieldValue} options={options} />
          </DrawerFormItem>

          <Button
            type="submit"
            block
            disabled={!isValid}
            isLoading={props.isLoading}
          >
            Speichern
          </Button>
        </DrawerForm>
      </form>
    </React.Fragment>
  );
};

const CloseChangeSection = (props) => {
  const EnhancedForm = withFormik({
    handleSubmit: (values) => {
      props.onSubmit && props.onSubmit();
    },
    validate: (values) => {
      return values.a && values.b && values.c && values.d
        ? {}
        : { a: "ausfüllen" };
    },
    mapPropsToValues: ({ ressource }) => ({
      a: null,
      b: null,
      c: null,
      d: null,
    }),
  })(CloseChangeForm);
  return (
    <DrawerSection title="Abschließen">
      <p>Bitte prüfe alle Arbeitschritte.</p>
      <br></br>

      <EnhancedForm {...props} />
    </DrawerSection>
  );
};

const RessourceInfo = ({
  ressource,
  onRequestEditSoftware,
  onRequestAddComment,
}) => {
  return (
    ressource && (

      <React.Fragment>
        {
          ressource.rolloutNumber == 6 &&
          <DrawerAlertBox>
            <div>
              <h2>Kaufgerät</h2>
              <p>
                Das Gerät ist ein Kaufgerät, das nicht Windows 11 fähig ist.
              </p>
            </div>
          </DrawerAlertBox>}
        <DrawerSection title="Ort">
          <DrawerInfoItem
            title="Location"
            value={ressource.location.siteOriginal}
          />
          <DrawerInfoItem
            title="Zusätliche Infos"
            value={ressource.location.otherInformation}
          />
        </DrawerSection>
        <DrawerSection title="Rechner">

          <DrawerInfoItem title="Ci Alt" value={ressource.ciName} />
          <DrawerInfoItem title="Ci Neu" value={ressource.ciNameNew} />
          <DrawerInfoItem
            title="Bezeichnung"
            value={ressource.deviceModelName}
          />
          <DrawerInfoItem title="SN Alt" value={ressource.serialNumber} />
          <DrawerInfoItem title="SN Neu" value={ressource.serialNumberNew} />
        </DrawerSection>
        <DrawerSection title="Software">
          <DrawerItem>
            {ressource.software &&
              ressource.software.reduce((acc, e, i) => {
                return i == 0 ? e : `${acc},  ${e}`;
              }, "")}
          </DrawerItem>
          <Button
            size="small"
            onClick={onRequestEditSoftware}
            variant="flat-primary"
            noPadding
          >
            Software bearbeiten
          </Button>
        </DrawerSection>

        <DrawerSection title="User">
          <DrawerInfoItem
            title="Name"
            value={`${ressource.customer.firstName || ""} ${ressource.customer
              .lastName || ""}`}
          />
          <DrawerInfoItem
            title="Stammnummer"
            value={ressource.customer.registrationNumber || "Nicht erfasst"}
          />
          <DrawerInfoItem title="Email" value={ressource.customer.email} />
          <DrawerInfoItem title="Phone" value={ressource.customer.phone} />
        </DrawerSection>
        <DrawerCommentSection
          onRequestAddComment={onRequestAddComment}
          title="Kommentare"
          comments={ressource.comments}
        />
      </React.Fragment>
    )
  );
};
const AppointmentInfo = ({ appointment }) => {
  const [_showUpdateDialog, _setShowUpdateDialog] = useState(false);

  return (
    appointment && (
      <React.Fragment>
        <UpdateAppointmentDialog
          appointment={appointment}
          isOpen={_showUpdateDialog}
          onRequestClose={() => {
            _setShowUpdateDialog(false);
          }}
        />
        <DrawerSection title="Termin">
          <DrawerInfoItem
            onClickEdit={() => {
              _setShowUpdateDialog(true);
            }}
            title="Datum"
            value={moment(appointment.startDate).format("LLL")}
          />
          <DrawerItem title="Status">
            <Badge value={appointment.status} />
          </DrawerItem>
        </DrawerSection>
      </React.Fragment>
    )
  );
};

const AppointmentDetailDrawer = (props) => {
  const {
    appointment,
    ressource,
    users,
    isAddingComment,
    isOpeningReport,
    openReport,
    isUpdatingRessource,
  } = props;

  const [_currentDetailPage, _setCurrentDetailPage] = useState("");

  const handleDoneBtnPresses = () => {
    props.updateAppointment(appointment.id, { status: "DONE" });
  };

  const handleRequestClose = () => {
    console.log("request close");
    props.onRequestClose && props.onRequestClose();
  };
  const handleAddUser = (user) => {
    props.assignUser(appointment.id, user);
  };
  const handleRemoveUser = (user) => {
    props.removeAssignee(appointment.id, user);
  };
  const handleCommentSubmit = (values) => {
    props.addComment(ressource.id, values);
  };
  const handleRequestUpdate = (values) => {
    props.updateRessource(ressource.id, values);
  };
  const handleRequestUnwind = () => {
    _setCurrentDetailPage("");
  };
  const handleUserConfirmationBtnPressed = () => {
    handleRequestUnwind();
    openReport(ressource.id);
  };

  const handleOpenConfirmationBtnPressed = () => {
    handleRequestUnwind();
    window.open(`${config.links.report}${ressource.id}`, '_blank', 'noreferrer');
  };
  return (
    <React.Fragment>
      <ReduxObserver
        event="ADD_COMMENT_SUCCESS"
        onEvent={handleRequestUnwind}
      />
      <ReduxObserver
        event="UPDATE_RESSOURCE_SUCCESS"
        onEvent={handleRequestUnwind}
      />

      <Drawer
        onRequestUnwind={handleRequestUnwind}
        onRequestClose={handleRequestClose}
        isOpen={props.appointment}
        showDetail={_currentDetailPage}
      >
        <DrawerPage title="Rechner" pageKey="MASTER">
          {appointment && (
            <ReduxObserver
              event={`UPDATE_APPOINTMENT_${appointment.id}`}
              onEvent={handleRequestClose}
            />
          )}

          <AppointmentInfo appointment={appointment} />

          <RessourceInfo
            onRequestAddComment={() => {
              _setCurrentDetailPage("ADD_COMMENT");
            }}
            onRequestEditSoftware={() => {
              _setCurrentDetailPage("EDIT_SOFTWARE");
            }}
            ressource={ressource}
          />
          <DrawerUserSection
            onRemove={handleRemoveUser}
            onSubmit={handleAddUser}
            assignees={appointment && appointment.assignees}
            users={users}
          />

          <DrawerSection title="Abschliesen">
            {ressource && !ressource.isConfirmedByCustomer && (
              <DrawerActionItem
                onClick={() => {
                  _setCurrentDetailPage("CONFIRMATION");
                }}
                isLoading={isOpeningReport}
                title="Protokoll"
                description="Protokoll öffnen"
                btnTitle="Öffnen"
                block
              />
            )}
            {appointment && appointment.status !== "DONE" && (
              <DrawerActionItem
                onClick={() => {
                  handleDoneBtnPresses();
                }}
                title="Abschließen"
                description="Abschließen um als erledigt zu markieren."
                btnTitle="Abschließen"
                isLoading={props.isUpdating}
                block
              />
            )}
          </DrawerSection>
        </DrawerPage>
        <DrawerPage title="Rechner" pageKey="ADD_COMMENT">
          <AddCommentSection
            isLoading={isAddingComment}
            onSubmit={handleCommentSubmit}
          />
        </DrawerPage>
        <DrawerPage title="Aufgabe" pageKey="CONFIRMATION">
          <SelectConfirmationSection
            onRequestOpenConfirmation={handleOpenConfirmationBtnPressed}
            onRequestSendUserConfirmation={handleUserConfirmationBtnPressed}
            isLoading={false}
            onSubmit={handleDoneBtnPresses}
          />
        </DrawerPage>
        <DrawerPage title="Aufgabe" pageKey="CLOSE_CHANGE">
          <CloseChangeSection
            isLoading={false}
            onSubmit={handleDoneBtnPresses}
          />
        </DrawerPage>

        <DrawerPage title="Rechner" pageKey="EDIT_SOFTWARE">
          <EditSoftwareSection
            isLoading={isUpdatingRessource}
            ressource={props.ressource}
            onSubmit={handleRequestUpdate}
          />
        </DrawerPage>
      </Drawer>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateAppointment: (id, data) => dispatch(updateAppointment(id, data)),
  assignUser: (id, user) => dispatch(assignUser(id, user)),
  removeAssignee: (id, user) => dispatch(removeAssignee(id, user)),
  updateRessource: (id, values) => dispatch(updateRessource(id, values)),
  addComment: (id, values) => dispatch(addComment(id, values)),
  openReport: (id) => dispatch(openReport(id)),
});

const mapStateToProps = (state, { id, ressource }) => ({
  ressource: selectAppointmentRessource(state, id ? id : null),
  appointment: selectAppointment(state, id),
  users: selectUsers(state),
  isUpdating: selectIsLoading(state, "UPDATE_APPOINTMENT"),
  isAddingUser: selectIsLoading(state, "ASSIGN_USER"),
  isAddingComment: selectIsLoading(state, "ADD_COMMENT"),
  isUpdatingRessource: selectIsLoading(state, "UPDATE_RESSOURCE"),
  isOpeningReport: selectIsLoading(state, "OPEN_REPORT"),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentDetailDrawer);
